import Button, { ButtonProps } from 'app/components/base/Button'
import useOpenExternalLink from 'app/hooks/useOpenExternalLink'

interface ExternalLinkButtonProps extends ButtonProps {
  link: string
}

const ExternalLinkButton: RFC<ExternalLinkButtonProps> = ({
  link,
  sx,
  ...props
}) => {
  const { openLink } = useOpenExternalLink()

  return (
    <Button
      sx={{ px: '10px', py: '7px', backgroundColor: 'common.black', ...sx }}
      onClick={() => openLink({ link })}
      {...props}
    />
  )
}

export default ExternalLinkButton
