import { memo, useMemo } from 'react'

import GridList from 'app/components/base/GridList'
import { useGetLikedListingsBySlug } from 'app/services/Query/listing/listing.query'
import useAuthStore, { getAuthUser } from 'app/store/auth.store'

import { searchListPlaceholder } from '../utils/placeholder'

import ListItem from './ListItem'

interface SearchListProps {
  results: ResultFields[]
  resultsPerPage: number
  isLoading: boolean
}

export const SearchList = memo<SearchListProps>(
  ({ results, resultsPerPage, isLoading }) => {
    const user = useAuthStore(getAuthUser)

    const placeholderResult = useMemo(
      () => searchListPlaceholder(resultsPerPage),
      [resultsPerPage]
    )

    const newResult = results?.length ? results : placeholderResult

    const listingSlugs = useMemo(
      () => results.map((el) => el.slug?.raw),
      [results]
    )

    const isEnabledLikedListings =
      !!listingSlugs?.length && !!results?.length && !!user?.id

    const { data: likedListings = [] } = useGetLikedListingsBySlug({
      listingSlugs: listingSlugs,
      options: { enabled: isEnabledLikedListings }
    })

    return (
      <GridList
        items={newResult}
        spacing={{ xs: 1.5, sm: 2, md: 3.75 }}
        columns={{ xs: 2, sm: 2, md: 4, lg: 5 }}
        keyExtractor={({ id }) => id.raw}
        renderItem={(item, index) => {
          const isLiked = likedListings?.length
            ? !!likedListings.find((el) => el.slug === item.slug?.raw)?.liked
            : false

          return (
            <ListItem
              index={index}
              item={item}
              loading={isLoading || !!item?.loading}
              isLiked={isLiked}
            />
          )
        }}
      />
    )
  }
)
