import { ReactNode } from 'react'

import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'

interface FooterBlockContainerProps {
  title: string
  children: ReactNode
}

const FooterBlockContainer: RFC<FooterBlockContainerProps> = ({
  title,
  children
}) => {
  return (
    <Stack direction="column" spacing={3}>
      <Text
        size={{ xs: 'subtitle1' }}
        sx={{
          textTransform: 'uppercase',
          fontWeight: 600,
          fontFamily: 'Nunito',
          color: 'text.third'
        }}
      >
        {title}
      </Text>

      {children}
    </Stack>
  )
}

export default FooterBlockContainer
