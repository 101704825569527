import { memo } from 'react'

import Box from 'app/components/base/Box'

import FilterPanelDesktop from './FilterPanelDesktop'
import FilterPanelMobile from './FilterPanelMobile'

const FilterPanel = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        pt: { xs: 2, md: 4 }
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <FilterPanelDesktop />
        <FilterPanelMobile />
      </Box>
    </Box>
  )
}

export default memo(FilterPanel)
