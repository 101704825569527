import { useIntl } from 'react-intl'

import GooglePlayIcon from 'app/assets/icons/footer/android.svg'
import AppStoreIcon from 'app/assets/icons/footer/apple.svg'
import Stack from 'app/components/base/Stack'
import { downloadAppUrls } from 'app/utils/constants/options.constants'

import ExternalLinkButton from '~/components/buttons/ExternalLinkButton'

import FooterBlockContainer from './FooterBlockContainer'

const buttonSx = {
  width: 130,
  backgroundColor: 'common.white',
  border: '1px solid',
  borderColor: 'common.black',
  px: 1.25,
  py: 0.5,
  '&:hover': {
    backgroundColor: 'common.white',
    transform: 'scale(1.05)'
  }
}

const DownloadOurApp = () => {
  const { formatMessage } = useIntl()

  return (
    <FooterBlockContainer
      title={formatMessage({ defaultMessage: 'Download Our App' })}
    >
      <Stack direction="row" spacing={1}>
        <ExternalLinkButton link={downloadAppUrls.apple} sx={buttonSx}>
          <AppStoreIcon />
        </ExternalLinkButton>

        <ExternalLinkButton link={downloadAppUrls.google} sx={buttonSx}>
          <GooglePlayIcon />
        </ExternalLinkButton>
      </Stack>
    </FooterBlockContainer>
  )
}

export default DownloadOurApp
