import React from 'react'
import { Platform } from 'react-native'

import { SearchBox as ElasticSearchBox } from '@elastic/react-search-ui'
import { SearchContextState } from '@elastic/react-search-ui/lib/esm/withSearch'
import { useIntl } from 'react-intl'

import TextInput, { TextInputProps } from 'app/components/base/TextInput'

type ElasticSearchBoxProps = React.ComponentProps<typeof ElasticSearchBox>

type SearchBoxProps = Omit<ElasticSearchBoxProps, 'view'> &
  Omit<TextInputProps, 'value' | 'onChange' | 'size'> &
  Pick<SearchContextState, 'filters' | 'searchTerm'> & {
    size?: 'large' | 'small'
  }

const heights = {
  large: 56,
  small: 48
}

const SearchBox = ({
  size = 'small',
  sx,
  filters,
  searchTerm = '',
  ...props
}: SearchBoxProps) => {
  const intl = useIntl()

  const height = heights[size]

  return (
    <ElasticSearchBox
      searchAsYouType
      shouldClearFilters={false}
      view={({ onChange, value }) => {
        const inputProps = Platform.select({
          web: {
            onChange: (e: any) => {
              onChange(e.target.value)
            }
          },
          native: {
            style: {
              height,
              lineHeight: 26
            },
            onChangeText: (v: any) => onChange(v)
          }
        })

        return (
          <TextInput
            placeholder={intl.formatMessage({ defaultMessage: 'Browse' })}
            value={value}
            {...(Platform.select({
              web: {
                size,
                inputProps: { sx: { pl: 3 } }
              }
            }) as any)}
            sx={{
              ...Platform.select({ web: { height } }),
              maxWidth: 470,
              width: '100%',
              flex: 1,
              backgroundColor: '#F6F9FF',
              borderRadius: '8px',
              ...sx
            }}
            inputProps={{ 'aria-label': 'Search' }}
            {...inputProps}
          />
        )
      }}
      {...props}
    />
  )
}

export default SearchBox
