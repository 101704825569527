import { defineMessages } from 'react-intl'

import { SHIPPING_STATUS_TYPE } from './status.constants'

export const messages = defineMessages({
  company: { defaultMessage: 'Company' },
  partnership: { defaultMessage: 'Partnership' },
  trust: { defaultMessage: 'Trust' },
  individual_or_sole_trader: { defaultMessage: 'Individual/Sole trader' },
  not_for_profit: { defaultMessage: 'Not-for-profit (NFP)' },
  other: { defaultMessage: 'Other' },
  not_using_this_account_anymore: {
    defaultMessage: "I'm not using this account anymore"
  },
  account_security_concerns_or_unauthorized_activity: {
    defaultMessage: 'Account security concerns/Unauthorized activity'
  },
  privacy_concerns: {
    defaultMessage: 'Privacy concerns'
  },
  new: { defaultMessage: 'New' },
  used: { defaultMessage: 'Used' },
  yes: { defaultMessage: 'Yes' },
  no: { defaultMessage: 'No' },
  genuine: { defaultMessage: 'Genuine' },
  aftermarket: { defaultMessage: 'Aftermarket' },
  sevenDays: { defaultMessage: '7 days' },
  fourteenDays: { defaultMessage: '14 days' },
  twentyOneDays: { defaultMessage: '21 days' },
  twentyEightDays: { defaultMessage: '28 days' },
  thirtyDays: { defaultMessage: '30 days' },
  fortyFiveDays: { defaultMessage: '45 days' },
  sixtyDays: { defaultMessage: '60 days' },
  ninetyDays: { defaultMessage: '90 days' },
  shippingOnly: { defaultMessage: 'Ship Only' },
  clickCollect: { defaultMessage: 'Click & Collect' },
  both: { defaultMessage: 'Both' },
  fixedPrice: { defaultMessage: 'Fixed price' },
  auction: { defaultMessage: 'Auction' },
  seller: {
    defaultMessage: 'Seller'
  },
  buyer: {
    defaultMessage: 'Buyer'
  },
  internationalShipping: {
    defaultMessage: 'International Shipping'
  },
  one_week: {
    defaultMessage: '1 week'
  },
  two_weeks: {
    defaultMessage: '2 weeks'
  },
  one_month: {
    defaultMessage: '1 month '
  },
  three_months: {
    defaultMessage: '3 months '
  },
  six_months: {
    defaultMessage: '6 months '
  },
  twelve_months: {
    defaultMessage: '12 months '
  },
  received_as_seller: {
    defaultMessage: 'Received as Seller'
  },
  received_as_buyer: {
    defaultMessage: 'Received as Buyer'
  },
  automotiveToolsEquipment: {
    defaultMessage: 'Automotive Tools & Equipment'
  },
  batteryRetailers: {
    defaultMessage: 'Battery Retailers'
  },
  bodyShop: {
    defaultMessage: 'Body Shop'
  },
  breakersYard: {
    defaultMessage: 'Breakers Yard'
  },
  distributor: {
    defaultMessage: 'Distributor'
  },
  fleetMaintenance: {
    defaultMessage: 'Fleet Maintenance'
  },
  lubricantsFluidsSupplier: {
    defaultMessage: 'Lubricants & Fluids Supplier'
  },
  manufacturer: {
    defaultMessage: 'Manufacturer'
  },
  mechanicRepairShop: {
    defaultMessage: 'Mechanic / Repair Shop'
  },
  motorFactor: {
    defaultMessage: 'Motor Factor'
  },
  racingPerformanceParts: {
    defaultMessage: 'Racing & Performance Parts'
  },
  retailer: {
    defaultMessage: 'Retailer'
  },
  tyreRetailerServices: {
    defaultMessage: 'Tyre Retailer & Services'
  },
  vehicleCustomization: {
    defaultMessage: 'Vehicle Customization'
  },
  wholesaler: {
    defaultMessage: 'Wholesaler'
  }
})

export const businessOptions = [
  {
    label: messages.company,
    value: 'company'
  },
  {
    label: messages.partnership,
    value: 'partnership'
  },
  {
    label: messages.trust,
    value: 'trust'
  },
  {
    label: messages.individual_or_sole_trader,
    value: 'individual_or_sole_trader'
  },
  {
    label: messages.not_for_profit,
    value: 'not_for_profit'
  },
  {
    label: messages.other,
    value: 'other'
  }
]

export const deleteOptions = [
  {
    label: messages.not_using_this_account_anymore,
    value: 'not_using_this_account_anymore'
  },
  {
    label: messages.account_security_concerns_or_unauthorized_activity,
    value: 'account_security_concerns_or_unauthorized_activity'
  },
  {
    label: messages.privacy_concerns,
    value: 'privacy_concerns'
  },
  {
    label: messages.other,
    value: 'other'
  }
]

export const conditionsOptions = [
  {
    label: messages.new,
    value: 'new_item'
  },
  {
    label: messages.used,
    value: 'used_item'
  }
]

export const warrantyOptions = [
  {
    label: messages.yes,
    value: 'yes'
  },
  {
    label: messages.no,
    value: 'no'
  }
]

export const warrantyDateOptions = [
  {
    label: messages.one_week,
    value: '1 week'
  },
  {
    label: messages.two_weeks,
    value: '2 weeks'
  },
  {
    label: messages.one_month,
    value: '1 month'
  },
  {
    label: messages.three_months,
    value: '3 months'
  },
  {
    label: messages.six_months,
    value: '6 months'
  },
  {
    label: messages.twelve_months,
    value: '12 months'
  }
]

export const typeOptions = [
  {
    label: messages.genuine,
    value: 'genuine'
  },
  {
    label: messages.aftermarket,
    value: 'aftermarket'
  }
]

export const durationOptions = [
  {
    label: messages.sevenDays,
    value: '7'
  },
  {
    label: messages.fourteenDays,
    value: '14'
  },
  {
    label: messages.twentyOneDays,
    value: '21'
  },
  {
    label: messages.twentyEightDays,
    value: '28'
  }
]

export const shippingOptions = [
  {
    label: messages.shippingOnly,
    value: SHIPPING_STATUS_TYPE.ship_only
  },
  {
    label: messages.clickCollect,
    value: SHIPPING_STATUS_TYPE.click_and_collect
  }
]

export const pricingOptions = [
  {
    label: messages.fixedPrice,
    value: 'fixed_price'
  },
  {
    label: messages.auction,
    value: 'auction'
  }
]

export const returnsPaidByOptions = [
  {
    label: messages.buyer,
    value: 'buyer'
  },
  {
    label: messages.seller,
    value: 'seller'
  }
]

export const refundOtherReasonOption = {
  id: 'other',
  text: messages.other
}

export const returnsDaysOptions = [
  {
    label: messages.sevenDays,
    value: '7'
  },
  {
    label: messages.fourteenDays,
    value: '14'
  },
  {
    label: messages.twentyOneDays,
    value: '21'
  },
  {
    label: messages.thirtyDays,
    value: '30'
  },
  {
    label: messages.fortyFiveDays,
    value: '45'
  },
  {
    label: messages.sixtyDays,
    value: '60'
  },
  {
    label: messages.ninetyDays,
    value: '90'
  }
]

export const receiveOptions = [
  {
    label: messages.received_as_seller,
    value: 'seller'
  },
  {
    label: messages.received_as_buyer,
    value: 'buyer'
  }
]

export const OTHER_BUSINESS_SEGMENT = 'other'

export const businessSegmentOptions = [
  {
    label: messages.automotiveToolsEquipment,
    value: 'automotive_tools_equipment'
  },
  {
    label: messages.batteryRetailers,
    value: 'battery_retailers'
  },
  {
    label: messages.bodyShop,
    value: 'body_shop'
  },
  {
    label: messages.breakersYard,
    value: 'breakers_yard'
  },
  {
    label: messages.distributor,
    value: 'distributor'
  },
  {
    label: messages.fleetMaintenance,
    value: 'fleet_maintenance'
  },
  {
    label: messages.lubricantsFluidsSupplier,
    value: 'lubricants_fluids_supplier'
  },
  {
    label: messages.manufacturer,
    value: 'manufacturer'
  },
  {
    label: messages.mechanicRepairShop,
    value: 'mechanic_repair_shop'
  },
  {
    label: messages.motorFactor,
    value: 'motor_factor'
  },
  {
    label: messages.racingPerformanceParts,
    value: 'racing_performance_parts'
  },
  {
    label: messages.retailer,
    value: 'retailer'
  },
  {
    label: messages.tyreRetailerServices,
    value: 'tyre_retailer_services'
  },
  {
    label: messages.vehicleCustomization,
    value: 'vehicle_customization'
  },
  {
    label: messages.wholesaler,
    value: 'wholesaler'
  },
  {
    label: messages.other,
    value: OTHER_BUSINESS_SEGMENT
  }
]

export const dangerListingShippingOptions = [
  {
    label: messages.clickCollect,
    value: SHIPPING_STATUS_TYPE.click_and_collect
  }
]

export const dayMessages = defineMessages({
  monday: { defaultMessage: 'Mon' },
  tuesday: { defaultMessage: 'Tue' },
  wednesday: { defaultMessage: 'Wed' },
  thursday: { defaultMessage: 'Thu' },
  friday: { defaultMessage: 'Fri' },
  saturday: { defaultMessage: 'Sat' },
  sunday: { defaultMessage: 'Sun' }
})

export const collectTimeDays = {
  Monday: dayMessages.monday,
  Tuesday: dayMessages.tuesday,
  Wednesday: dayMessages.wednesday,
  Thursday: dayMessages.thursday,
  Friday: dayMessages.friday,
  Saturday: dayMessages.saturday,
  Sunday: dayMessages.sunday
}

export const downloadAppUrls = {
  google: 'https://play.google.com/store/apps/details?id=com.ifnd.autoparts',
  apple: 'https://apps.apple.com/gb/app/ifndautoparts/id1669191950'
}
