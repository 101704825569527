import { useIntl } from 'react-intl'

import { useGetAllStaticPagesQuery } from 'app/services/Query/static-pages/static-pages.query'
import { ADDITIONAL_STATIC_PAGES } from 'app/utils/constants/staticPage.constants'
import { STATIC_PAGE_VISIBILITY } from 'app/utils/constants/status.constants'
import {
  implementAdditionalStaticPage,
  selectStaticPages
} from 'app/utils/helpers/staticPage.helpers'

export const useFooter = () => {
  const { formatMessage } = useIntl()

  const { data, isSuccess } = useGetAllStaticPagesQuery({
    options: {
      select: (data) =>
        selectStaticPages({
          data,
          visibility: STATIC_PAGE_VISIBILITY.marketplace
        })
    }
  })

  const defaultStaticPages = [
    {
      name: formatMessage({ defaultMessage: 'Learn more' }),
      static_pages: [
        ADDITIONAL_STATIC_PAGES.blog,
        ADDITIONAL_STATIC_PAGES.helpCenter
      ] as StaticByTitle[]
    },
    {
      name: formatMessage({ defaultMessage: 'Get in touch' }),
      static_pages: [ADDITIONAL_STATIC_PAGES.contactUs] as StaticByTitle[]
    }
  ]

  const dataWithContactUsPage = implementAdditionalStaticPage({
    data,
    pages: [ADDITIONAL_STATIC_PAGES.contactUs],
    toColumnNumber: 3
  })

  const staticPagesQuery = implementAdditionalStaticPage({
    data: dataWithContactUsPage,
    pages: [ADDITIONAL_STATIC_PAGES.blog, ADDITIONAL_STATIC_PAGES.helpCenter],
    toColumnNumber: 2
  })

  const staticPages = isSuccess ? staticPagesQuery : defaultStaticPages
  const year = new Date().getFullYear()

  return { staticPages, year }
}
