import { IS_NATIVE_APP, WEBSITE_URL } from 'app/utils/constants/env.constants'

import Axios from '../Axios'

const baseUrl = IS_NATIVE_APP ? `${WEBSITE_URL}/api` : '/api'

const NextApi = new Axios({
  baseURL: baseUrl,
  headers: { 'Content-Type': 'application/json' }
})

// interceptor for passing the secret key to the each native app request
if (IS_NATIVE_APP) {
  NextApi.interceptors.request.use(
    (config) => {
      return {
        ...config,
        params: {
          ...config.params,
          secret: process.env.EXPO_GOOGLE_SECRET_TOKEN
        }
      }
    },
    (err) => Promise.reject(err)
  )
}

export default NextApi
