import { FormattedMessage } from 'react-intl'

import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'

const ShowMore = () => {
  return (
    <Stack spacing={2} sx={{ pt: { xs: 6, md: 5 }, pb: 7.75 }}>
      <Text variant="subtitle1" component="p" color="text.third">
        <FormattedMessage defaultMessage="Discover the future of auto part trading with ifndautoparts, your specialized platform for all things automotive. From rare vintage treasures to essential modern components, our platform is designed to connect people with the parts they need, transforming garages and businesses alike. Navigate with ease through our intuitive app or website, where buying and selling car parts is not just a transaction, but a part of the automotive passion. With features like 'My Garage' for personalized searches, 'Click & Collect' for immediate needs, and secure payment options, we bring the world of auto parts to your fingertips. Whether cruising the streets, racing on tracks, or adventuring off-road, ifndautoparts is your trusted partner in every automotive journey. We're more than just a platform; we're a community where every part and person finds their perfect match. Step into the world of ifndautoparts – where your automotive dreams become a reality." />
      </Text>
    </Stack>
  )
}

export default ShowMore
