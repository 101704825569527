import ApplePaySVG from 'app/assets/icons/footer/apple-pay.svg'
import GooglePaySVG from 'app/assets/icons/footer/google-pay.svg'
import MastercardSVG from 'app/assets/icons/footer/mastercard.svg'
import PayPalSVG from 'app/assets/icons/footer/paypal.svg'
import VisaSVG from 'app/assets/icons/footer/visa.svg'
import hcvaMemberSrc from 'app/assets/images/hcva-member.png'
import Box from 'app/components/base/Box'
import Image from 'app/components/base/Image'
import Stack from 'app/components/base/Stack'

const PaymentsAndMembers = () => {
  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="row" spacing={1}>
        <VisaSVG />

        <MastercardSVG />

        <PayPalSVG />

        <GooglePaySVG />

        <ApplePaySVG />
      </Stack>

      <Box sx={{ width: 72, height: 72 }}>
        <Image
          src={hcvaMemberSrc}
          width={72}
          height={72}
          sizes="256px"
          alt="hcva-member"
          placeholder="blur"
          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMUEHaQAQABHwCBJOqiVAAAAABJRU5ErkJggg=="
        />
      </Box>
    </Stack>
  )
}

export default PaymentsAndMembers
