import React, { useMemo, useState } from 'react'

import { withSearch } from '@elastic/react-search-ui'
import type { SearchContextState } from '@elastic/react-search-ui/lib/cjs/withSearch'
import { helpers } from '@elastic/search-ui'
import { useIntl } from 'react-intl'
import { RefetchQueryFilters, useQueryClient } from 'react-query'

import { CheckboxProps } from 'app/components/base/Checkbox'
import Text from 'app/components/base/Text'
import MissingVehicleDialog from 'app/components/dialogs/MissingVehicleDialog'
import { transformGenerationOptions } from 'app/components/forms/template/GeneralListingFormTemplate/helpers/carInfo.helpers'
import useCurrencyState from 'app/hooks/useCurrencyState'
import useEffectAfterMount from 'app/hooks/useEffectAfterMount'
import useModal from 'app/hooks/useModal'
import CheckboxFilter from 'app/libs/elastic/filters/CheckboxFilter'
import SelectFilter, {
  SelectFilterProps
} from 'app/libs/elastic/filters/SelectFilter'
import SliderRangeFilter, {
  SliderRangeFilterProps
} from 'app/libs/elastic/filters/SliderRangeFilter'
import ToggleButtonFilter, {
  ToggleButtonFilterProps
} from 'app/libs/elastic/filters/ToggleButtonFilter'
import GoogleReCaptchaV3Provider from 'app/providers/GoogleRecaptchaV3Provider'
import {
  getCategories,
  getMakes,
  getModels,
  getSubCategories,
  getVehicleSeries,
  getVehicleTrims
} from 'app/services/Api/listing'
import { useGetListingsPriceRang } from 'app/services/Query/listing/listing.query'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'
import { MAKE_SOURCE_TYPE } from 'app/utils/constants/listing.constants'
import { getSubcategoryHeader } from 'app/utils/helpers/listings.helpers'
import {
  transformIntlOptions,
  transformPartNumber
} from 'app/utils/helpers/transforms.helper'

import { generateYearsOptions } from '../../CreateListingFeature/helpers/general.helpers'
import {
  conditionOptions,
  itemTypeOptions,
  shippingTypeOptions,
  warrantyOptions
} from '../utils/search-feature.options'

type SearchSelectFilterProps = Omit<SelectFilterProps, 'field' | 'filterType'>
type SearchRangeFilterProps = Omit<
  SliderRangeFilterProps,
  'field' | 'filterType'
>
type SearchToggleFilterProps = Omit<
  ToggleButtonFilterProps,
  'field' | 'filterType' | 'label' | 'options'
>

const { findFilterValues } = helpers

type DependentFilterProps = {
  field: string
  filterType: 'any' | 'all'
  dependentField: string
  helperFieldName?: string
  children: (props: any) => JSX.Element
}

type DependentFilterContext = Pick<
  SearchContextState,
  'filters' | 'removeFilter'
>

const DependentFilter = withSearch<
  DependentFilterProps,
  DependentFilterContext
>(({ filters, removeFilter }) => ({
  filters,
  removeFilter
}))(
  ({
    field,
    filterType,
    dependentField,
    removeFilter,
    filters,
    helperFieldName,
    children
  }) => {
    const values = findFilterValues(filters || [], field, filterType)
    const helperValue = findFilterValues(
      filters || [],
      helperFieldName,
      filterType
    )
    const stringified = values?.join()

    useEffectAfterMount(() => {
      if (!stringified) {
        removeFilter(dependentField, undefined, filterType)
      }
    }, [stringified])

    if (typeof children === 'function') {
      return children({ values, helperValue })
    }

    return children
  }
)

export const PartNumberFilter = React.memo((props: SearchSelectFilterProps) => {
  const intl = useIntl()

  return (
    <SelectFilter
      field="part_number"
      divider
      label={intl.formatMessage({ defaultMessage: 'Part Number' })}
      withFilter
      withCreateOption
      transformValue={transformPartNumber}
      withOptions
      {...props}
    />
  )
})

export const MakeFilter = React.memo((props: SearchSelectFilterProps) => {
  const intl = useIntl()
  const { open, showModal, hideModal } = useModal()
  const [isEnabled, setEnabled] = useState(false)

  const handleOpen = () => {
    if (isEnabled) return

    setEnabled(true)
  }

  return (
    <>
      <SelectFilter
        field="makes"
        dependentField="models"
        divider
        filterType="any"
        sortOption
        label={intl.formatMessage({ defaultMessage: 'Make' })}
        helperText={
          <Text
            variant="subtitle2"
            sx={{
              color: 'text.secondary',
              ...(!IS_NATIVE_APP && {
                '&:hover': {
                  cursor: 'pointer'
                }
              })
            }}
            onClick={showModal}
          >
            {intl.formatMessage({ defaultMessage: 'Vehicle Missing?' })}
          </Text>
        }
        optionSettings={{
          enabled: isEnabled,
          key: 'makes',
          loadOptions: () => getMakes([MAKE_SOURCE_TYPE.imported]),
          optionValue: 'name',
          optionLabel: 'name'
        }}
        withFilter
        onOpen={handleOpen}
        {...props}
      />

      {open && (
        <GoogleReCaptchaV3Provider>
          <MissingVehicleDialog
            open={open}
            onClose={hideModal}
            onConfirm={hideModal}
          />
        </GoogleReCaptchaV3Provider>
      )}
    </>
  )
})

export const ModelFilter = React.memo((props: SearchSelectFilterProps) => {
  const intl = useIntl()

  return (
    <DependentFilter field="makes" filterType="any" dependentField="models">
      {({ values }) => (
        <SelectFilter
          field="models"
          dependentField="generation_id"
          divider
          filterType="any"
          sortOption
          label={intl.formatMessage({ defaultMessage: 'Model' })}
          optionSettings={{
            key: values,
            enabled: !!values?.length,
            loadOptions: () => {
              return getModels({
                by_make_name: values
              })
            },
            optionValue: 'name',
            optionLabel: 'name'
          }}
          disabled={!values?.length}
          withFilter
          {...props}
        />
      )}
    </DependentFilter>
  )
})

export const GenerationFilter = React.memo((props: SearchSelectFilterProps) => {
  const intl = useIntl()

  const queryClient = useQueryClient()

  return (
    <DependentFilter
      field="models"
      filterType="any"
      dependentField="generation_id"
      helperFieldName="makes"
    >
      {({ values, helperValue }) => (
        <SelectFilter
          field="generation_id"
          dependentField="serie_id"
          divider
          filterType="any"
          sortOption
          label={intl.formatMessage({ defaultMessage: 'Generation' })}
          optionSettings={{
            key: values,
            enabled: !!values?.length && !!helperValue?.length,
            loadOptions: async () => {
              await queryClient.refetchQueries({
                queryKey: ['options', helperValue],
                type: 'active',
                exact: true
              } as RefetchQueryFilters)

              const cacheDataFromModels = queryClient.getQueryData<Model[]>(
                ['options', helperValue],
                {
                  exact: false
                }
              )

              const modelIndex = cacheDataFromModels?.findIndex(
                (model) => model.name === values[0]
              )

              if (modelIndex === -1 || modelIndex === undefined) return []

              const modelId = cacheDataFromModels?.[modelIndex]?.id

              return transformGenerationOptions([modelId!])
            },
            optionValue: 'id',
            optionLabel: 'name'
          }}
          disabled={!values?.length}
          withFilter
          {...props}
        />
      )}
    </DependentFilter>
  )
})

export const SeriesFilter = React.memo((props: SearchSelectFilterProps) => {
  const intl = useIntl()

  return (
    <DependentFilter
      field="generation_id"
      filterType="any"
      dependentField="serie_id"
    >
      {({ values }) => (
        <SelectFilter
          field="serie_id"
          dependentField="trim_id"
          divider
          filterType="any"
          sortOption
          fullWidth
          label={intl.formatMessage({ defaultMessage: 'Series' })}
          optionSettings={{
            key: values,
            enabled: !!values?.length,
            loadOptions: () => getVehicleSeries({ byGenerationId: values }),
            optionValue: 'id',
            optionLabel: 'name'
          }}
          disabled={!values?.length}
          withFilter
          {...props}
        />
      )}
    </DependentFilter>
  )
})

export const TrimFilter = React.memo((props: SearchSelectFilterProps) => {
  const intl = useIntl()

  return (
    <DependentFilter field="serie_id" filterType="any" dependentField="trim_id">
      {({ values }) => (
        <SelectFilter
          field="trim_id"
          filterType="any"
          sortOption
          fullWidth
          label={intl.formatMessage({ defaultMessage: 'Trim' })}
          optionSettings={{
            key: values,
            enabled: !!values?.length,
            loadOptions: () => getVehicleTrims({ bySerieId: values }),
            optionValue: 'id',
            optionLabel: 'name'
          }}
          disabled={!values?.length}
          withFilter
          {...props}
        />
      )}
    </DependentFilter>
  )
})

export const YearFilter = React.memo((props: SearchSelectFilterProps) => {
  const [options] = useState(() => generateYearsOptions(1991))
  const intl = useIntl()

  return (
    <SelectFilter
      field="year"
      filterType="any"
      label={intl.formatMessage({ defaultMessage: 'Year' })}
      optionSettings={{
        options,
        optionLabel: 'label',
        optionValue: 'value'
      }}
      {...props}
    />
  )
})

export const CategoryFilter = (props: SearchSelectFilterProps) => {
  const intl = useIntl()

  return (
    <SelectFilter
      field="category_id"
      dependentField="sub_category_id"
      divider
      filterType="any"
      sortOption
      label={intl.formatMessage({
        defaultMessage: 'Item Category'
      })}
      optionSettings={{
        key: 'category',
        loadOptions: getCategories,
        optionValue: 'id',
        optionLabel: 'name'
      }}
      withFilter
      {...props}
    />
  )
}

export const SubCategoryFilter = (props: SearchSelectFilterProps) => {
  const intl = useIntl()

  return (
    <DependentFilter
      field="category_id"
      filterType="any"
      dependentField="sub_category_id"
    >
      {({ values }) => (
        <SelectFilter
          field="sub_category_id"
          filterType="any"
          groupBy={getSubcategoryHeader}
          sortOption
          label={intl.formatMessage({
            defaultMessage: 'Sub-Category'
          })}
          optionSettings={{
            key: values,
            enabled: !!values?.length,
            loadOptions: () =>
              getSubCategories({
                by_category_id: values[0]
              }),
            optionValue: 'id',
            optionLabel: 'name'
          }}
          disabled={!values?.length}
          withFilter
          {...props}
        />
      )}
    </DependentFilter>
  )
}

export const ShippingTypeFilter = (props: SearchSelectFilterProps) => {
  const intl = useIntl()

  const options = useMemo(
    () => transformIntlOptions(shippingTypeOptions, intl.formatMessage),
    [intl.locale]
  )

  return (
    <SelectFilter
      field="shipping_types"
      filterType="any"
      label={intl.formatMessage({
        defaultMessage: 'Shipping'
      })}
      optionSettings={{
        options,
        optionValue: 'value',
        optionLabel: 'label'
      }}
      {...props}
    />
  )
}

export const PriceFilter = ({
  sliderProps = {},
  ...props
}: SearchRangeFilterProps) => {
  const intl = useIntl()
  const { currency } = useCurrencyState()

  const { data } = useGetListingsPriceRang({})

  const min = Number(data?.min_price) || 0
  const max = Number(data?.max_price) || 999999

  const formatCurrency = (v) =>
    intl.formatNumber(v, {
      style: 'currency',
      currency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    })

  const marks = [
    {
      value: min,
      label: formatCurrency(min)
    },
    {
      value: max,
      label: formatCurrency(max)
    }
  ]

  return (
    <SliderRangeFilter
      field="price"
      direction="row-reverse"
      min={min}
      max={max}
      sx={{ flex: 1 }}
      spacing={3}
      {...props}
      sliderProps={{
        marks,
        getAriaValueText: formatCurrency,
        valueLabelFormat: formatCurrency,
        ...sliderProps,
        sx: {
          '& .MuiSlider-markLabel[data-index="0"]': {
            fontSize: '14px',
            color: 'text.third',
            transform: 'translateX(-8px)'
          },
          '& .MuiSlider-markLabel[data-index="1"]': {
            fontSize: '14px',
            color: 'text.third',
            transform: 'translateX(calc(-100% + 8px))'
          },
          ...sliderProps?.sx
        }
      }}
    />
  )
}

export const CountryFilter = (props: SearchSelectFilterProps) => {
  const intl = useIntl()

  return (
    <SelectFilter
      field="full_country_name"
      dependentField="city"
      divider
      filterType="any"
      sortOption
      label={intl.formatMessage({
        defaultMessage: 'Country'
      })}
      withOptions
      withFilter
      {...props}
    />
  )
}

export const CityFilter = (props: SearchSelectFilterProps) => {
  const intl = useIntl()

  return (
    <DependentFilter
      field="full_country_name"
      filterType="any"
      dependentField="city"
    >
      {({ values }) => (
        <SelectFilter
          field="city"
          divider
          sortOption
          filterType="any"
          label={intl.formatMessage({
            defaultMessage: 'City'
          })}
          disabled={!values?.length}
          withOptions
          withFilter
          {...props}
        />
      )}
    </DependentFilter>
  )
}

export const ConditionFilter = (props: SearchToggleFilterProps) => {
  const intl = useIntl()
  const options = useMemo(
    () => transformIntlOptions(conditionOptions, intl.formatMessage),
    [intl.locale]
  )

  return (
    <ToggleButtonFilter
      {...props}
      label={intl.formatMessage({
        defaultMessage: 'Condition'
      })}
      field="condition"
      options={options}
    />
  )
}

export const WarrantyFilter = (props: SearchToggleFilterProps) => {
  const intl = useIntl()
  const options = useMemo(
    () => transformIntlOptions(warrantyOptions, intl.formatMessage),
    [intl.locale]
  )

  return (
    <ToggleButtonFilter
      {...props}
      label={intl.formatMessage({ defaultMessage: 'Warranty' })}
      field="warranty"
      options={options}
    />
  )
}

export const ItemTypeFilter = (props: SearchToggleFilterProps) => {
  const intl = useIntl()
  const options = useMemo(
    () => transformIntlOptions(itemTypeOptions, intl.formatMessage),
    [intl.locale]
  )

  return (
    <ToggleButtonFilter
      {...props}
      label={intl.formatMessage({ defaultMessage: 'Type' })}
      field="item_type"
      options={options}
    />
  )
}

export const InternationalShippingFilter = (props: CheckboxProps) => {
  const intl = useIntl()
  const handleChange = ({ value, onChange, onRemove }) => {
    const transformValue = value ? 'yes' : 'no'

    if (value) {
      onChange([transformValue])
    } else {
      onRemove(undefined)
    }
  }
  return (
    <CheckboxFilter
      {...props}
      label={intl.formatMessage({
        defaultMessage: 'International Shipping'
      })}
      field="international_shipping"
      handleChange={handleChange}
    />
  )
}
